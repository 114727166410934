<template>
  <v-row>
    <v-col cols="12" class="d-grid">
      <span class="lato-black" style="font-size: 24px"
        >Cordatus Version Management</span
      >
    </v-col>
    <v-col md="12" lg="2">
      <v-navigation-drawer width="100%" permanent>
        <v-list nav class="pl-0">
          <v-list-item-group v-model="item" color="primary">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              @click="$router.push('/version-management/' + item.path)"
            >
              <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="lato-bold font-16">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <v-col md="12" lg="9">
      <router-view></router-view>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'versionManagement',
  data: () => ({
    item: 0,
    items: [
      {
        title: 'Client Version',
        path: 'client-version',
        icon: 'mdi-laptop'
      },
      {
        title: 'Stream Engine Version',
        path: 'stream-engine-version',
        icon: 'mdi-alpha-s-box-outline'
      },
      {
        title: 'Inference Engine Version',
        path: 'inference-engine-version',
        icon: 'mdi-alpha-i-box-outline'
      },
      {
        title: 'COSSA Version',
        path: 'cossa-version',
        icon: 'mdi-alpha-c-box-outline'
      }
    ]
  })
}
</script>

<style scoped>
</style>
